import React from 'react'
import Layout from "../components/Layout"
import { Helmet } from 'react-helmet'

export default function NotFound() {
    return (
      <Layout>
      <Helmet>
        <title>404 | Use Less Divs</title>

        <meta name="description" content="A blog about html, css, javascript, focusing on accessibility" />
        <meta property="og:title" content="404 | Use Less Divs" />
        <meta property="og:description" content="A blog about html, css, javascript, focusing on accessibility" />
        <meta property="og:image" content="https://uselessdivs.com/img/og-img.png" />
        <meta property="og:image:alt" content="Use Less Divs" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://uselessdivs.com/privacy/" />
        <link rel="canonical" href="https://uselessdivs.com/privacy/" />
      </Helmet>
        <main>
          <div className="container">
            <div className="row align-items-center mb-5">
              <div className="col-md-6 col-lg-7 mb-5 mb-md-0">
                <h1 className="h1 mb-4">Oops! We couldn't find that page.</h1>
                <p>No page with that URL was found.</p>
                <p className="mb-5">Maybe the address was typed wrong, the page is no longer exists or it was pulled down to the abyss by an octopus.</p>
               <a href="/" className="primary-btn">Go to main page</a>
              </div>
              <div className="col-md-6 col-lg-5">
                <img src="/img/404.svg" alt="" width="1840" height="1380" />
              </div>
            </div>
          </div>
        </main>
      </Layout>
    )
}
